import axios from 'axios'
import store from '../store'

let getLocationInvoicesToken = null

export default {
  methods: {
    async getInvoiceById(invoiceId) {
      const response = await axios.get(
        `${process.env.VUE_APP_API_BASE_URL}/api/v1/invoice/${invoiceId}`,
        {
          headers: {
            'Content-type': 'application/json',
            apiToken: process.env.VUE_APP_API_KEY,
            profileToken: store.getters['profileStore/getProfileToken']
          }
        }
      )
      return response
    },

    async getInvoicePdf(invoiceId) {
      const response = await axios.get(
        `${process.env.VUE_APP_API_BASE_URL}/api/v1/invoice/${invoiceId}/pdf`,
        {
          headers: {
            'Content-type': 'application/json',
            apiToken: process.env.VUE_APP_API_KEY,
            profileToken: store.getters['profileStore/getProfileToken']
          }
        }
      )
      return response
    },

    async getInvoiceMails(invoiceId) {
      const response = await axios.get(
        `${process.env.VUE_APP_API_BASE_URL}/api/v1/invoice/${invoiceId}/mails`,
        {
          headers: {
            'Content-type': 'application/json',
            apiToken: process.env.VUE_APP_API_KEY,
            profileToken: store.getters['profileStore/getProfileToken']
          }
        }
      )
      return response
    },

    async getLocationInvoices(
      locationId = 0,
      companyId = 0,
      startDate = '',
      endDate = '',
      q = '',
      page = 1,
      itemsPerPage = 25,
      statusId = -1
    ) {
      if (getLocationInvoicesToken) {
        getLocationInvoicesToken.cancel()
      }
      getLocationInvoicesToken = axios.CancelToken.source()

      const response = await axios.get(
        `${process.env.VUE_APP_API_BASE_URL}/api/v1/invoice/location/${locationId}`,
        {
          cancelToken: getLocationInvoicesToken.token,

          params: {
            companyId: companyId,
            q: q,
            startDate: startDate,
            endDate: endDate,
            page: page,
            itemsPerPage: itemsPerPage,
            statusId: statusId
          },

          headers: {
            'Content-type': 'application/json',
            apiToken: process.env.VUE_APP_API_KEY,
            profileToken: store.getters['profileStore/getProfileToken']
          }
        }
      )
      return response
    },

    async getLocationExpiredInvoices(
      locationId = 0,
      companyId = 0,
      startDate = '',
      endDate = '',
      q = '',
      page = 1,
      itemsPerPage = 25
    ) {
      if (getLocationInvoicesToken) {
        getLocationInvoicesToken.cancel()
      }
      getLocationInvoicesToken = axios.CancelToken.source()

      const response = await axios.get(
        `${process.env.VUE_APP_API_BASE_URL}/api/v1/invoice/location/${locationId}/expired`,
        {
          cancelToken: getLocationInvoicesToken.token,

          params: {
            companyId: companyId,
            q: q,
            startDate: startDate,
            endDate: endDate,
            page: page,
            itemsPerPage: itemsPerPage
          },

          headers: {
            'Content-type': 'application/json',
            apiToken: process.env.VUE_APP_API_KEY,
            profileToken: store.getters['profileStore/getProfileToken']
          }
        }
      )
      return response
    },

    async getTotalExpiredInvoices(locationId = 0, companyId = 0, q = '') {
      if (getLocationInvoicesToken) {
        getLocationInvoicesToken.cancel()
      }
      getLocationInvoicesToken = axios.CancelToken.source()

      const response = await axios.get(
        `${process.env.VUE_APP_API_BASE_URL}/api/v1/invoice/location/${locationId}/expired/total`,
        {
          cancelToken: getLocationInvoicesToken.token,

          params: {
            companyId: companyId,
            q: q
          },

          headers: {
            'Content-type': 'application/json',
            apiToken: process.env.VUE_APP_API_KEY,
            profileToken: store.getters['profileStore/getProfileToken']
          }
        }
      )
      return response
    },

    async acceptInvoice(invoiceId) {
      let criteria = {
        InvoiceId: invoiceId
      }

      const response = await axios.put(
        `${process.env.VUE_APP_API_BASE_URL}/api/v1/invoice/${invoiceId}/accept`,
        JSON.stringify(criteria),
        {
          headers: {
            'Content-type': 'application/json',
            apiToken: process.env.VUE_APP_API_KEY,
            profileToken: store.getters['profileStore/getProfileToken']
          }
        }
      )
      return response
    },

    async rejectInvoice(invoiceId) {
      let criteria = {
        InvoiceId: invoiceId
      }

      const response = await axios.put(
        `${process.env.VUE_APP_API_BASE_URL}/api/v1/invoice/${invoiceId}/reject`,
        JSON.stringify(criteria),
        {
          headers: {
            'Content-type': 'application/json',
            apiToken: process.env.VUE_APP_API_KEY,
            profileToken: store.getters['profileStore/getProfileToken']
          }
        }
      )
      return response
    },

    async creditInvoice(invoiceId) {
      let criteria = {
        InvoiceId: invoiceId
      }

      const response = await axios.put(
        `${process.env.VUE_APP_API_BASE_URL}/api/v1/invoice/${invoiceId}/credit`,
        JSON.stringify(criteria),
        {
          headers: {
            'Content-type': 'application/json',
            apiToken: process.env.VUE_APP_API_KEY,
            profileToken: store.getters['profileStore/getProfileToken']
          }
        }
      )
      return response
    },

    async createReservationInvoice(reservationId, isDeposit) {
      let criteria = {
        IsDeposit: isDeposit
      }

      const response = await axios.post(
        `${process.env.VUE_APP_API_BASE_URL}/api/v1/invoice/reservation/${reservationId}`,
        JSON.stringify(criteria),
        {
          headers: {
            'Content-type': 'application/json',
            apiToken: process.env.VUE_APP_API_KEY,
            profileToken: store.getters['profileStore/getProfileToken']
          }
        }
      )
      return response
    },

    async setInvoiceReminderStatus(invoiceId, reminderStatus, sendMail) {
      let criteria = {
        InvoiceId: invoiceId,
        ReminderStatus: reminderStatus,
        SendMail: sendMail
      }

      const response = await axios.put(
        `${process.env.VUE_APP_API_BASE_URL}/api/v1/invoice/${invoiceId}/reminder`,
        JSON.stringify(criteria),
        {
          headers: {
            'Content-type': 'application/json',
            apiToken: process.env.VUE_APP_API_KEY,
            profileToken: store.getters['profileStore/getProfileToken']
          }
        }
      )
      return response
    },

    async getLocationInvoiceSettings(locationId) {
      const response = await axios.get(
        `${process.env.VUE_APP_API_BASE_URL}/api/v1/invoice/settings`,
        {
          params: {
            locationId: locationId
          },

          headers: {
            'Content-type': 'application/json',
            apiToken: process.env.VUE_APP_API_KEY,
            profileToken: store.getters['profileStore/getProfileToken']
          }
        }
      )
      return response
    },

    async updateLocationInvoiceSettings(settings) {
      const response = await axios.put(
        `${process.env.VUE_APP_API_BASE_URL}/api/v1/invoice/settings`,
        JSON.stringify(settings),
        {
          headers: {
            'Content-type': 'application/json',
            apiToken: process.env.VUE_APP_API_KEY,
            profileToken: store.getters['profileStore/getProfileToken']
          }
        }
      )
      return response
    },

    async getLocationInvoiceTexts(locationId) {
      const response = await axios.get(
        `${process.env.VUE_APP_API_BASE_URL}/api/v1/invoice/texts`,
        {
          params: {
            locationId: locationId
          },

          headers: {
            'Content-type': 'application/json',
            apiToken: process.env.VUE_APP_API_KEY,
            profileToken: store.getters['profileStore/getProfileToken']
          }
        }
      )
      return response
    },

    async updateLocationInvoiceTexts(texts) {
      const response = await axios.put(
        `${process.env.VUE_APP_API_BASE_URL}/api/v1/invoice/texts`,
        JSON.stringify(texts),
        {
          headers: {
            'Content-type': 'application/json',
            apiToken: process.env.VUE_APP_API_KEY,
            profileToken: store.getters['profileStore/getProfileToken']
          }
        }
      )
      return response
    },

    /**
     * Save invoice footer text
     * @param {number} invoiceId
     * @param {string} footer
     */
    async saveInvoiceFooter(invoiceId, footer) {
      const response = await axios.put(
        `${process.env.VUE_APP_API_BASE_URL}/api/v1/invoice/${invoiceId}/footer`,
        JSON.stringify({
          Footer: footer
        }),
        {
          headers: {
            'Content-type': 'application/json',
            apiToken: process.env.VUE_APP_API_KEY,
            profileToken: store.getters['profileStore/getProfileToken']
          }
        }
      )
      return response
    },

    /**
     * Save invoice address data
     * @param {number} invoiceId
     * @param {string} address
     * @param {string} postalCode
     * @param {string} City
     * @param {string} poNumber
     * @param {string} sendToName
     * @param {string} emailTo
     * @param {string} companyName
     */
    async saveInvoiceAddress(
      invoiceId = 0,
      address = '',
      postalCode = '',
      city = '',
      poNumber = '',
      companyName = '',
      sendToName = '',
      emailTo = '',
      VatNumber = ''
    ) {
      let criteria = {
        Address: address,
        PostalCode: postalCode,
        City: city,
        PoNumber: poNumber,
        SendToName: sendToName,
        EmailTo: emailTo,
        CompanyName: companyName,
        VatNumber: VatNumber
      }

      const response = await axios.put(
        `${process.env.VUE_APP_API_BASE_URL}/api/v1/invoice/${invoiceId}/address`,
        JSON.stringify(criteria),
        {
          headers: {
            'Content-type': 'application/json',
            apiToken: process.env.VUE_APP_API_KEY,
            profileToken: store.getters['profileStore/getProfileToken']
          }
        }
      )
      return response
    },

    async saveInvoiceDetail(invoiceId = 0, reference = '', issueDate, dueDate) {
      let criteria = {
        Reference: reference,
        IssueDate: issueDate,
        DueDate: dueDate
      }

      const response = await axios.put(
        `${process.env.VUE_APP_API_BASE_URL}/api/v1/invoice/${invoiceId}/detail`,
        JSON.stringify(criteria),
        {
          headers: {
            'Content-type': 'application/json',
            apiToken: process.env.VUE_APP_API_KEY,
            profileToken: store.getters['profileStore/getProfileToken']
          }
        }
      )
      return response
    },

    async createCollectiveInvoice(
      locationId,
      invoiceAddress,
      reservationIds,
      isDeposit = false
    ) {
      let criteria = {
        LocationId: locationId,
        InvoiceAddress: invoiceAddress,
        ReservationIds: reservationIds,
        IsDeposit: isDeposit
      }

      const response = await axios.post(
        `${process.env.VUE_APP_API_BASE_URL}/api/v1/invoice/collective`,
        JSON.stringify(criteria),
        {
          headers: {
            'Content-type': 'application/json',
            apiToken: process.env.VUE_APP_API_KEY,
            profileToken: store.getters['profileStore/getProfileToken']
          }
        }
      )
      return response
    }
  }
}
