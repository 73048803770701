<template>
  <ui-component-modal
    modalTitle="Pick your invoice type"
    :buttonLabelConfirm="$t('Form.Control.Create')"
    :isSaving="isSaving"
    :isSavingSuccess="isSavingSuccess"
    :isSavingError="isSavingError"
    :hideFooterButtons="isSavingError || isSavingSuccess"
    :onClickCancel="onClickCancel"
    :showModal="showModal"
    :onClickSave="createInvoice"
    @closeModal="onClickCancel"
  >
    <template slot="modalTitle"></template>
    <template v-slot:content>
      <div class="has-margin-bottom">
        What type of invoice do you want to create?
      </div>

      <div class="tabs is-toggle">
        <ul>
          <li :class="{ 'is-active': !isDeposit }">
            <a @click="isDeposit = false">
              <span>Regular</span>
            </a>
          </li>
          <li :class="{ 'is-active': isDeposit }">
            <a @click="isDeposit = true">
              <span>Deposit</span>
            </a>
          </li>
        </ul>
      </div>
    </template>
  </ui-component-modal>
</template>

<script>
import Vue from 'vue'
import { mapState, mapMutations } from 'vuex'
import { EventBus } from '@/eventbus/event-bus'
import invoiceProvider from '@/providers/invoice'
import reservationProvider from '@/providers/reservation'

export default {
  components: {},

  props: {
    showModal: {
      type: Boolean,
      default: false,
    },

    onClickCancel: {
      type: Function,
      required: true,
    },
  },

  data() {
    return {
      isDeposit: false,
      isSaving: false,
      isSavingSuccess: false,
      isSavingError: false,
    }
  },

  computed: {
    ...mapState('reservationStore', ['reservation']),
  },

  created() {},

  methods: {
    ...mapMutations('reservationStore', ['setReservation']),

    createInvoice() {
      let self = this

      if (!self.isCreatingInvoice && self.reservation.HasInvoice === 0) {
        self.isSaving = true

        invoiceProvider.methods
          .createReservationInvoice(self.reservation.Id, self.isDeposit)
          .then((response) => {
            if (response.status === 200) {
              let invoice = response.data

              if (self.isDeposit) {
                self.reservation.HasDepositInvoice = invoice.Id
              }

              if (!self.isDeposit) {
                self.reservation.HasInvoice = invoice.Id
              }

              // Update store
              self.setReservation(self.reservation)

              self.isSavingSuccess = true

              // Set timer to close popup in in 1,5 seconds
              setTimeout(() => {
                this.onClickCancel()
              }, 1500)

              // Send message to display toast on screen
              EventBus.$emit('showToast', {
                type: 'info',
                message: 'Invoice created',
              })
            }
          })
          .catch((error) => {
            self.isSavingError = true
          })
          .finally(() => {
            self.isSaving = false
          })
      }
    },

    goToInvoice(invoiceId) {
      this.$router.push({
        name: `invoices-list-detail`,
        params: { invoiceId: invoiceId },
      })
    },
  },
}
</script>
